import api from "./api";

const url = "compliance";

const findActive = () => api.get(`${url}/active`);

const findBySlug = (slug) => api.get(`${url}/${slug}`);

const findActiveExternalCompliances = () => api.get(`${url}/active-external`);

const externalBySlug = (slug) => api.get(`${url}/external-by-slug/${slug}`);

export { findActive, findBySlug, findActiveExternalCompliances, externalBySlug };
