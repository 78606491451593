<template>
  <read-article :article="foundCompliance" />
</template>

<script>
import { externalBySlug } from '../../services/compliance-service'

export default {
  name: 'ReadCompliance',
  data () {
    return {
      foundCompliance: {}
    }
  },
  async created () {
    this.foundCompliance = await externalBySlug(this.$route.params.slug)
  }
}
</script>

<style scoped>

</style>
